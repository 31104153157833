@import 'Styles/includes';

.Root {
    $root: &;

    &__Error {
        padding: 12px;
        margin: 16px 0;
        font-size: 1.6rem;
        border-radius: 4px;
        background-color: $red-5;
        border: 1px solid $red;
    }

    &__Form {
        :global {
            @extend %richtext;
            @extend %gravityforms;
        }
    }

    &__Confirmation {
        :global {
            @extend %richtext;
        }
    }
}
